@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

:root {
  /* Main theme color */
  --portal-main-color: #04abbb;
  --portal-theme-primary: #04abbb;
  --portal-theme-primary-filter-color: invert(74%) sepia(96%) saturate(396%)
    hue-rotate(332deg) brightness(101%) contrast(93%);
  --portal-theme-secondary: #04abbb29;
  --text-primary-color: white;
  --text-secondary-color: rgb(150, 150, 150);
  --text-color: white;
  /* Main background colors */
  --background-primary-color: black;
  --background-secondary-color: black;
  /* Sidebar Properties */
  --sidebars-text-color: white;
  --sidebars-active-color: #04abbb;
  /* --sidebars-hover-color: #b7849b; */
  --sidebars-background-color: #1d1c1d;
  /* Popup / Popover properties  */
  --popup-background-color: black;
  --popup-text-color: white;
  /* Element properties */
  --input-text-color: #fff;
  --input-placeholder-color: #fff;
  --input-background-primary: transparent;
  --input-background-secondary: #fff;
  --icons-color-allover: #04abbb;
  /* Button Properties */
  --button-background-color: #1c1c1c;
  --button-text-color: #04abbb;
  --button-outlined-color: #04abbb;
  --button-hover-color: "#04abbb";
  --audio-color: "#1d1c1d";
  --calendar-selected-box: #8b8b8b;
  --datepicker-selected-color: #fff;
}
/*  in chrome this works */
/* ::-webkit-calendar-picker-indicator {
  filter: invert(1);
} */
body {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  background-color: var(--background-primary-color) !important;
  color: var(--text-primary-color) !important;
}

.what-include {
  margin-top: 0px !important;
}
.secondary-background {
  background-color: var(--background-secondary-color);
}
.css-17mra34-MuiPaper-root-MuiCard-root {
  background-color: transparent !important;
  color: var(--text-primary-color);
}
.css-2tnm32.Mui-selected {
  background-color: var(--portal-theme-primary) !important;
}

::-webkit-media-controls-time-remaining-display,
audio::-webkit-media-controls-current-time-display {
  color: var(--portal-theme-primary);
}
::-webkit-media-controls-enclosure {
  background-color: var(--portal-theme-secondary);
}

.css-18dh5wg-MuiTypography-root {
  color: var(--text-primary-color) !important;
}
.css-6obpvq-MuiTypography-root-MuiLink-root {
  color: var(--text-primary-color);
}
/* calender colors */
.MuiCalendarPicker-root {
  background-color: var(--popup-background-color) !important;
  color: var(--input-text-color);
}
.MuiCalendarPicker-root button {
  background-color: transparent !important;
  color: var(--input-text-color);
}
.MuiCalendarPicker-root .Mui-selected {
  background-color: var(--portal-theme-primary) !important;
  color: var(--datepicker-selected-color);
}

.MuiClockPicker-root {
  background-color: var(--popup-background-color) !important;
  color: var(--input-text-color);
}
.MuiClockPicker-root button {
  background-color: transparent !important;
  color: var(--input-text-color);
}
.MuiClockPicker-root span {
  background-color: transparent !important;
  color: var(--input-text-color);
}
.MuiClockPicker-root .Mui-selected {
  background-color: var(--portal-theme-primary) !important;
  color: var(--datepicker-selected-color);
}
.no-scroll::-webkit-scrollbar {
  /* display: none; */
}
.no-scroll::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.no-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.no-scroll::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 50px;
}

/* Handle on hover */
.no-scroll::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  filter: var(--portal-theme-primary-filter-color);
  cursor: pointer;
}
.css-66mibm-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active
  .MuiTableSortLabel-icon {
  opacity: 0;
  color: #637381;
}
/* calender colors end */

/* card background */
/* .css-1dg1xmf-MuiPaper-root-MuiCard-root {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
} */
.css-1mav5hp-MuiPaper-root-MuiDrawer-paper {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}
.css-ju84cc-MuiButtonBase-root-MuiMenuItem-root,
.css-1h0kuck-MuiButtonBase-root-MuiButton-root {
  color: var(--text-primary-color);
}
.css-1fuveia-MuiPaper-root-MuiPopover-paper,
.css-13pz97u {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}
.css-1uc7r6i {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}
/* .css-1bsqz8-MuiPaper-root-MuiDrawer-paper {
  color: var(--sidebar-normal-color);
} */
.css-1ti1ykt-MuiPaper-root-MuiPopover-paper {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}
.css-15ez4hi-MuiPaper-root-MuiAccordion-root.Mui-expanded:last-of-type {
  background-color: var(--background-secondary-color);
  color: var(--text-primary-color);
}
.css-alxk9h-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: var(--text-primary-color);
}
.css-13isf56-MuiInputBase-input-MuiOutlinedInput-input,
.css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--text-primary-color);
}
/* fieldset {
  border-color: var(--portal-theme-secondary) !important;
}
fieldset:focus {
  border-color: var(--portal-theme-primary) !important;
} */
/* .svg-color svg,
.MuiCalendarPicker-root svg {
  color: white;
} */
.camera {
  opacity: 0;
  transition: 0.2s all;
  color: rgba(55, 141, 212, 1);
}
.camera:hover {
  opacity: 1;
  color: rgba(55, 141, 212, 1);
}
.css-qua2zy-MuiToolbar-root {
  background: transparent !important;
}
.css-vpap0f-MuiButton-startIcon > *:nth-of-type(1) {
  font-size: 22px;
  /* color: var(--portal-main-color); */
}
/* calendar selected Color */
.css-14ewge7.Mui-selected,
.css-14ewge7.Mui-selected:hover,
.css-rbocvz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover,
.css-s80gtz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: var(--portal-theme-primary) !important;
}
.css-1qwrva7-MuiButtonBase-root-MuiListItemButton-root:before {
  background-color: var(--portal-theme-primary);
}
/* calender selected hover */
.css-2tnm32.Mui-selected:hover,
.css-s80gtz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
  will-change: background-color;
  background-color: var(--portal-theme-primary);
}

/* .css-y5nl51-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-y5nl51-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: var(--portal-theme-primary);
} */
.css-1w4063c-MuiTypography-root,
.css-rq00hd-MuiTypography-root {
  cursor: pointer;
}
.css-e9crry-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--text-primary-color);
}
.css-105feo8-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root:hover {
  background-color: var(--portal-theme-primary);
}
.css-105feo8-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root {
  /* background-color: var(--portal-theme-primary); */

  color: var(--background-primary-color);
}
.css-rxrcxl-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--text-primary-color);
}
.css-1bsqz8-MuiPaper-root-MuiDrawer-paper {
  background-color: transparent;
}
.css-1gxhmaf-MuiListItemIcon-root {
  color: var(--portal-theme-primary) !important;
}
.css-x6t0nh-MuiButtonBase-root-MuiIconButton-root {
  color: var(--portal-theme-primary) !important;
}
.css-mtn039 {
  background-color: #132144;
  color: white;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow: hidden;
  box-shadow: rgba(0, 3, 82, 0.24) 0px 0px 2px 0px,
    rgba(0, 3, 82, 0.24) 0px 16px 32px -4px;
  border-radius: 16px;
  position: relative;
  z-index: 0;
  cursor: pointer;
}
.css-1dg1xmf-MuiPaper-root-MuiCard-root {
  background-color: var(--background-secondary-color);
  color: var(--text-primary-color);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  overflow: hidden;
  box-shadow: 0 0 2px 0 rgba(0, 3, 82, 0.24),
    0 16px 32px -4px rgba(0, 3, 82, 0.24);
  border-radius: 16px;
  position: relative;
  z-index: 0;
  cursor: pointer;
}
*,
::after,
::before {
  box-sizing: border-box;
}
.css-733kmc-MuiStack-root {
  background-color: var(--sidebars-background-color);
}
.css-jjtu05 {
  background-color: var(--sidebars-background-color);
}
.css-1mav5hp-MuiPaper-root-MuiDrawer-paper {
  box-shadow: none;
}
.css-1qro46r
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall
  .MuiAutocomplete-input {
  padding: 2.5px 4px 2.5px 6px;
  color: var(--input-text-color);
  /* black for light theme time zone in edit profile
  color: black; */
}
.css-kd0yc-MuiPaper-root-MuiAutocomplete-paper,
.css-1clbjpy {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}
.css-1nuss9t {
  color: var(--text-primary-color);
}
.css-40mfy8-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--text-primary-color);
}
.css-lvwv31 {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
  border: 1px solid var(--portal-theme-primary);
}
.css-pt151d {
  color: var(--portal-theme-primary);
}

.css-yzm7vx,
.css-184p1yy,
.css-12ff9sa,
.css-12ff9sa {
  color: var(--text-primary-color);
}
.review-card {
  background-color: var(--background-secondary-color);
  color: var(--text-primary-color);
}

.css-k7695x-MuiPaper-root-MuiAppBar-root {
  background-color: var(--background-primary-color);
  /* z-index: 11111 !important; */
}
.css-3ioe56 {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}
.css-1hes0mo:hover {
  color: var(--portal-theme-primary);
}
.css-66zhem {
  background-color: var(--background-primary-color);
}
.css-ze1xs7 {
  color: var(--portal-theme-primary) !important;
}
.mui-card-button .css-i4bv87-MuiSvgIcon-root {
  /* color: white !important; */
  /* color: var(--portal-theme-primary) !important; */
}
.mui-card-button .css-vubbuv,
.program-lock-icon .css-vubbuv {
  /* fill: #04abbb;
  fill: white; */
}
.css-f0u5qy {
  background-color: var(--sidebars-background-color);
  color: var(--text-primary-color);
}
.css-12ff9sa {
  color: none;
}
.css-1s2atx1-MuiButtonBase-root-MuiIconButton-root {
  color: var(--portal-theme-primary);
}
.css-r13mc8 {
  color: var(--portal-theme-primary);
}
.centerAlign,
.centerAlign0 {
  align-items: center;
  justify-content: center;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.mui-custom-card-content {
  padding: 15px 15px 20px;
}
.new-memories {
  background-color: var(--sidebars-background-color);
  color: var(--portal-theme-primary);
}
.css-yxnldu {
  background-color: var(--sidebars-background-color);
}
/* .css-i4bv87-MuiSvgIcon-root {
  color: var(--button-text-color);
} */
.css-1bfvxec {
  color: var(--text-primary-color);
}
.css-yzm7vx {
  color: var(--text-primary-color);
}
.css-vb81xk-MuiInputBase-root-MuiOutlinedInput-root {
  color: var(--text-primary-color);
}
.css-zuwxiq {
  color: var(--text-primary-color);
}
.css-1v6giby {
  background-color: var(--background-primary-color);
  color: var(--text-primary-color);
}
.css-13d5z5v {
  color: var(--background-primary-color);
  /* background-image: url(../images/btn-img.png); */
}
.css-13d5z5v:hover {
  background-color: var(--portal-theme-primary);
  color: var(--background-primary-color);
}
.css-q6wzhr {
  color: var(--portal-theme-primary);
}
.css-zuwxiq {
  color: var(--text-primary-color);
}
.css-2300kw {
  color: var(--text-primary-color);
}
.program-lock-icon .css-vubbuv {
  /* fill: var(--portal-theme-primary); */
}
.css-vubbuv {
  /* /* for light theme */
  /* fill: var(--portal-theme-primary); */
  /* fill: white !important; */
}
/* .css-4cxmk4, */
.css-admp4s-MuiButtonBase-root-MuiIconButton-root {
  color: var(--portal-theme-primary);
}
.card {
  background-color: var(--background-secondary-color);
}
.modal-theme {
  background-color: var(--sidebars-background-color);
}
.css-1xjb83h {
  color: var(--text-primary-color);
}
.css-rmquh4-MuiButtonBase-root-MuiChip-root {
  color: var(--portal-theme-primary);
  border: 1px solid var(--portal-theme-primary);
}
.css-1tir071-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--text-primary-color);
}
.css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--text-primary-color);
}
.css-muvvuh {
  color: var(--text-primary-color);
}
.transaction-screen {
  background-color: var(--background-primary-color);
}
.css-2300kw.Mui-disabled {
  color: var(--text-primary-color);
}
.css-1tc40pv {
  color: var(--input-text-color);
}
.css-ijridm-MuiTypography-root {
  color: var(--text-color);
}
.css-3ioe56.Mui-expanded:last-of-type {
  background-color: var(--sidebars-background-color);
}
/* HINA KHAN IN DARK MODE CONVERSION ENDS HERE */

h1 {
  font-size: 40px;
  color: var(--portal-theme-primary);
}
h2 {
  font-size: 30px;
  color: var(--portal-theme-primary);
}
h3 {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.5rem !important;
}
h4 {
  font-size: 1rem;
  font-weight: 500;
}

.normal-text {
  font-size: 14px;
  font-weight: 500;
}
.text-secondary {
  color: var(--text-secondary-color) !important;
}

a:hover {
  text-decoration: none !important;
  color: var(--portal-theme-primary) !important;
}

legend {
  float: unset;
}

.circular-progress {
  margin-left: 50%;
  margin-top: 20%;
}
.text-area-task {
  background-color: var(--input-background-secondary);
  outline: none;
  color: black !important;
}
.notes {
  background-color: white !important;
  border: 1px solid var(--portal-main-color) !important;
}
.heading {
  color: var(--portal-main-color);
}
.inputs-fields {
  /* background-color: var(--input-background-primary); */
  /* background-color: green; */
}
.form-control:focus {
  background-color: var(--textarea-color);
  color: var(--text-primary-color);
  border: 1px solid var(--portal-theme-primary);
}
.form-control {
  border: none;
  color: black;
}
.programm-card {
  background-color: var(--background-secondary-color);
}

.programm-card h3 {
  color: var(--portal-theme-primary);
}
.programm-card p span {
  color: var(--text-secondary-color);
}
.lesson-card h4 {
  color: var(--portal-theme-primary);
}
.textarea-color {
  background-color: var(--textarea-color);
}
.textarea-block textarea {
  color: var(--text-primary-color);
}
.simplebar-wrapper {
  height: 400px;
  overflow: hidden;
}
.simplebar-content-wrapper {
  overflow: scroll;
  background-color: var(--sidebars-background-color);
}

.task-label {
  font-family: "Poppins", sans-serif;
}

textarea:hover {
  box-shadow: none;
}

/* .custom-popover-icon {
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  color: var(--portal-theme-primary) !important;
} */

.custom-popover li {
  z-index: 10;
  font-size: 12px;
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--portal-theme-primary);
}

.programme-card-desc {
  margin-top: 0px !important;
  font-size: 14px;
  color: var(--text-primary-color);
}

.programme-duration {
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 0px !important;
  color: var(--text-secondary-color);
}

.lesson-notes-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-primary-color);
}

.programme-content {
  padding: 15px 15px 20px 15px;
}

.lesson-content {
  padding: 15px 15px 20px 15px;
}

.hovr-effect:hover {
  color: var(--background-primary-color);
}

.small-contained-button {
  /* background-image: url(../images/btn-img.png); */
  background-color: var(--button-background-color) !important;
  /* background-color: #1a93a9; */
  padding: 8px 16px;
  color: var(--button-text-color) !important;
  border-radius: 5px;
  border: 1px solid var(--button-outlined-color);
}
.small-contained-set-button {
  background-color: #1c1c1c !important;
  background-color: #e9e9e9 !important;
  border: 1px solid var(--portal-theme-primary);
  border: 1px solid #ffffff;
  border-radius: 5px;

  color: #000;
  padding: 0px 0px;
}
.small-contained-dialog-button {
  background-color: var(--button-background-color) !important;
  color: var(--button-text-color);
  border-radius: 5px;
}
.small-contained-button-checked {
  /* background-image: url(../images/btn-img.png); */
  background-color: rgb(207, 207, 207) !important;
  /* background-color: #1a93a9; */
  padding: 8px 16px;
  color: var(--button-text-color);
  border-radius: 5px;
  border: 1px solid var(--button-outlined-color);
}

.small-outlined-button {
  background-color: var(--button-background-color);
  padding: 8px 16px;
  color: var(--background-primary-color);
  border-radius: 5px;
  border: 2px solid var(--portal-theme-primary);
}
.css-1fd40pz-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(55, 141, 212, 1) !important;
}
label .css-i4bv87-MuiSvgIcon-root {
  /* color: white !important; */
  /* color: var(--portal-theme-primary) !important; */
}

.css-i4bv87-MuiSvgIcon-root {
  /* color: white !important; */
  /* color: var(--portal-theme-primary) !important; */
}
.program-btn {
  text-align: left;
  margin-bottom: 20px;
}
.program-category-btn {
  text-align: right;
  margin-bottom: 20px;
}

.form-label-lesson {
  font-weight: bold;
}

.feedback-programme {
  font-size: 25px;
}

.normal-font {
  font-size: 14px;
  font-weight: 500;
}

.feedback-programme-header {
  display: flex;
  justify-content: center;
}

.feedback-programme-text {
  padding: 10px;
  font-weight: bold;
  margin-bottom: 0;
}

.bold-heading {
  font-weight: bold;
}

.section-space {
  margin-top: 0.75rem !important;
}

.rating-stars {
  float: right;
  padding-bottom: 15px;
  padding-right: 15px;
}

.program-font-size {
  font-size: 14px;
  font-weight: 500;
}

.heading-subtitle {
  font-size: 14px;
  font-weight: 700;
}

.icon-style {
  color: var(--text-primary-color);
  float: right;
}

.icon-style:hover {
  color: var(--portal-theme-primary);
  border-radius: 50%;
}

.lesson-card {
  background-color: var(--background-secondary-color);
  cursor: pointer;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 5px 10px -4px rgb(0 3 82 / 24%);
  color: var(--text-primary-color);
}

.payment-card {
  background-color: var(--background-secondary-color);
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 5px 10px -4px rgb(0 3 82 / 24%);
  color: var(--text-primary-color);
}
.payment-card .request-type {
  font-weight: 500;
}

.data-table {
  color: var(--text-primary-color);
  text-align: center;
}
.data-table p {
  color: var(--text-secondary-color);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}
.data-table .css-n1f7dt-MuiPaper-root-MuiCard-roo {
  border-radius: 0;
}
.css-qen1a1-MuiDataGrid-root {
  border: 2px solid var(--portal-theme-secondary) !important;
}
.data-table .MuiDataGrid-cell,
.css-1sstdvt-MuiDataGrid-columnHeaders {
  border-bottom: 1px solid white;
}
.data-table .MuiDataGrid-row {
  border-bottom: 0.5px solid var(--portal-theme-primary);
}
.data-table .css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  color: var(--portal-theme-primary) !important;
}
.data-table .MuiDataGrid-menuList,
.css-oau2hf-MuiPaper-root {
  background-color: var(--background-secondary-color) !important;
  color: var(--text-primary-color);
}

.data-table .css-1wph1rq-MuiDataGrid-overlay {
  background-color: var(--background-secondary-color) !important;
}
.loagin-button.Mui-disabled {
  background-color: var(--portal-theme-secondary) !important;
}
.loagin-button .MuiLoadingButton-loadingIndicator {
  color: var(--portal-theme-primary) !important;
}
.loagin-button:hover {
  background-color: var(--portal-theme-secondary);
}
.MuiDataGrid-iconSeparator {
  display: none !important;
}
.date-color {
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.back-screen-button {
  background-color: transparent !important;
  color: var(--portal-theme-primary) !important;
  margin-bottom: 10px;
}
.back-screen-button:hover {
  background-color: var(--portal-theme-secondary) !important;
}

.media-margin {
  margin-top: 0.75rem !important;
}

.back-arrow-margin {
  margin-bottom: 10px;
}

.css-19kzrtu {
  padding: 0px 0px;
}

.memories-date p {
  color: var(--text-primary-color);
  margin-top: 15px;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  color: var(--portal-theme-secondary) !important;
}
.carousel-nav-button {
  background-color: var(--text-primary-color) !important;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  padding-top: 12px;
  /* padding-right: 5px; */
}
.css-d82q1s-MuiButtonBase-root-MuiButton-root {
  background-color: transparent !important;
  color: var(--text-primary-color) !important;
}

.submit-button {
  text-align: center;
  /* background-image: url(../images/btn-img.png); */
  background-color: var(--button-background-color);
  border: 1px solid var(--button-outlined-color);
  color: var(--button-text-color);

  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 12px;
}
.upload-button label {
  text-align: center !important;
  background-color: var(--portal-theme-secondary);
  padding: 15px;
  color: var(--portal-theme-primary);
  border-radius: 5px;
  cursor: pointer;
  height: 76px;
  width: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview {
  position: relative;
}
.preview img {
  height: 76px;
  width: 76px;
  border-radius: 5px;
  margin-right: 10px;
  display: inline;
}
.preview span {
  position: absolute;
  right: 3px;
  top: -10px;
  font-size: 13px;
  font-weight: bold;
  height: 20px;
  background-color: red;
  width: 20px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
}

.add-photo .preview span,
.add-memories-preview .preview span {
  top: -35px;
}
.preview span:hover {
  cursor: pointer;
}
.new-memories .MuiFormControl-root {
  width: 100%;
}

/* .event-details {
  margin: 20px auto;
} */
.event-details h1 {
  font-size: 30px;
}

.new-memories h1 {
  font-size: 20px;
  margin: 20px auto;
}
#player {
  width: 100% !important;
}
.card-full-width {
  width: 100%;
}
.rating-stars-box {
  position: absolute;
  bottom: 0%;
}
.download-icon {
  margin-top: 12px;
}

.remove-border {
  border: none !important;
  margin: 0 !important;
  z-index: 1111 !important;
}
.remove-border svg {
  z-index: 1 !important;
}
.sidebar-icon svg {
  color: var(--button-text-color) !important;
}
.set-display-inline .remove-border,
.affirmation-dots .remove-border {
  display: inline;
  position: absolute;
  top: 5px;
  color: var(--portal-theme-secondary);
  right: 5px;
  width: 36px;
  height: 36px;
  border-radius: 50% !important;
  background-color: var(--portal-theme-secondary) !important;
  text-align: center;
}

.Ninty-Day-trackerList-dots .remove-border {
  display: inline;
  position: absolute;
  top: 60px;
  color: var(--portal-theme-secondary);
  right: 5px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--portal-theme-secondary);
  text-align: center;
}
.set-image-center img {
  margin: auto;
}

/* .player-wrapper {
  position: relative;
  padding-top: 56.25%;
} */

/* .react-player {
  position: absolute;
  top: 0;
  left: 0;
} */

.wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.accordion-summary svg {
  color: var(--portal-theme-primary);
}

.range-slider span {
  color: var(--portal-theme-primary);
}
.css-1e799a4-MuiListItemIcon-root {
  color: var(--portal-theme-primary);
}
.notes-input {
  padding: 15px;
  padding-right: 30px;
}

.slider-box {
  position: relative;
}
.join-now-btn {
  position: absolute;
  bottom: 25px;
}
.event-slider .slick-prev:before,
.event-slider .slick-next:before {
  color: var(--portal-theme-primary);
}
.event-slider .slick-prev,
.event-slider .slick-next {
  z-index: 1;
}
.menu-background-circle {
  /* height: 35px;
  width: 35px; */
  border-radius: 51px;
  /* background-color: #145b6c29; */
}
.programm-svg:hover {
  cursor: pointer;
}
.review-slider .slick-track {
  display: flex;
  flex-wrap: wrap;
}
.review-slider .slick-slide {
  flex: 0 0 auto;
  height: auto !important;
}
.review-slider .slick-slide > div {
  background-color: var(--background-secondary-color);
  border: 1px solid var(--button-outlined-color);
  height: 100%;
  border-radius: 5px;
}
.review-slider .card-border {
  border: none;
}
.dashboard-circle {
  height: 565px;
}
/* chat style Support Tickets*/
.chat-main-card {
  background-color: var(--sidebars-background-color) !important;
  color: var(--text-primary-color) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
  rgba(0, 0, 0, 0.1) 0px 0px 1px 0px !important;
}
.chat-main-card .card-title {
  color: var(--portal-theme-primary);
}
.chat-title-head {
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
}
.chat-message-head {
  border-bottom: 1px solid gray;
}
.chat-title-body {
  border-right: 1px solid gray;
}
.chat-message-body {
  /* background-color: rgba(158, 163, 161, 0.08) !important; */
  /* border-top: 1px solid #282727; */
  position: relative;
  width: 100%;
}
.modal-content {
  background-color: var(--sidebars-background-color);
}
.mui-btn-close {
  /* color: var(--button-outlined-color) !important; */
  text-align: right;
}
.mui-btn-close-icon {
  position: absolute;
  right: 3px;
  top: 3px;
}
.chat-main-card hr {
  margin: 0;
}
.chat-side-bar {
  padding-right: 0 !important;
}
.chat-title-body:hover {
  background-color: rgba(158, 163, 161, 0.08) !important;
  cursor: pointer;
}

.uiDataGrid-sortIcon {
  display: none;
}

.open-ticket-color {
  background-color: #007bff;
  color: var(--input-text-color);
  border-color: #007bff;
  width: 90px;
}
.solved-ticket-color {
  background-color: #28a745;
  color: var(--input-text-color);
  border-color: #28a745;
  width: 90px;
}
.waiting-ticket-color {
  background-color: #ffc107;
  color: #000;
  border-color: #ffc107;
  width: 90px;
}

.css-qen1a1-MuiDataGrid-root .MuiDataGrid-cell:focus,
.css-rvx2y1 .MuiDataGrid-cell:focus,
.css-rvx2y1 .MuiDataGrid-columnHeader:focus {
  outline: none;
}
.dx-sort {
  display: none !important;
}
.suppor-ticket-data-table .MuiDataGrid-row {
  cursor: pointer;
  border: 1px solid white;
}
.suppor-ticket-data-table .MuiIconButton-sizeSmall {
  display: none;
}
.suppor-ticket-data-table .MuiDataGrid-cell:focus,
.suppor-ticket-data-table .MuiDataGrid-columnHeader:focus {
  outline: none;
}
.suppor-ticket-data-table .MuiDataGrid-columnHeaders {
  border: 2px solid white !important;
  color: var(--portal-theme-primary) !important;
  width: 99% !important;
}
.suppor-ticket-data-table {
  border: 2px solid var(--portal-theme-secondary) !important;
}
.suppor-ticket-data-table .MuiDataGrid-iconSeparator {
  cursor: pointer;
}
.suppor-ticket-data-table .MuiDataGrid-virtualScrollerContent {
  min-height: 200px !important;
  border: 1px solid white;
  overflow-x: hidden;
  width: 99% !important;
}

.ticket_image_preview img:hover {
  cursor: pointer;
}
.SnackbarItem-action {
  display: inline;
  max-width: 80px;
  vertical-align: middle;
  position: absolute;
  right: 10px;
}

/* for datepicker background on mobile */
.MuiDialog-paperScrollPaper {
  background-color: var(--popup-background-color);
  color: var(--input-text-color);
}
/* end chat style Support Tickets*/

.associate-section {
  padding-left: 40px;
  color: var(--text-primary-color);
}

@media only screen and (max-width: 375px) {
  /* .custom-video-player {
    height: 100% !important;
  } */
  .lesson-heading {
    padding-top: 10px;
  }
  .card-spacing {
    margin-top: 0.75rem;
  }
  .mobile-margin {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .preview img {
    height: auto;
  }
  .fc-button-primary {
    padding: 3px !important;
  }
  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-left: 0;
  }
  .fc-toolbar-title {
    font-size: 16px !important;
  }
}
.mobile-margin {
  margin-top: 10px;
}
.MuiTableCell-root {
  color: var(--text-primary-color) !important;
  background-color: var(--sidebars-background-color) !important;
}
.MuiPopover-paper {
  background-color: var(--sidebars-background-color) !important;
  color: var(--text-primary-color) !important;
}
.MuiTableSortLabel-root,
.MuiTableCell-root,
.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows,
.MuiSelect-select {
  /* color: white; */
}
.MuiTableContainer-root::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

/* Handle */
.MuiTableContainer-root::-webkit-scrollbar-thumb {
  background: #636262;
  border-radius: 10px;
}
::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--sidebars-background-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.success {
  color: #229a16;
  background-color: rgba(84, 214, 44, 0.16);
}
.error {
  color: rgb(216, 55, 55);
  background-color: rgba(255, 72, 66, 0.16);
}
.cancel {
  color: white;
  background-color: rgba(216, 216, 216, 0.16);
}
.data-table {
  color: var(--text-primary-color);
  text-align: center;
}

.data-table p {
  color: var(--text-secondary-color);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.data-table .css-n1f7dt-MuiPaper-root-MuiCard-roo {
  border-radius: 0;
}

.css-qen1a1-MuiDataGrid-root {
  border: 2px solid var(--portal-theme-secondary) !important;
}

.data-table .MuiDataGrid-cell,
.css-1sstdvt-MuiDataGrid-columnHeaders {
  border-bottom: 1px solid var(--portal-theme-secondary);
}

.data-table .css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  color: var(--portal-theme-primary) !important;
}

.data-table .MuiDataGrid-menuList,
.css-oau2hf-MuiPaper-root {
  background-color: var(--background-secondary-color) !important;
  color: var(--text-primary-color);
}

.data-table .css-1wph1rq-MuiDataGrid-overlay {
  background-color: var(--background-secondary-color) !important;
}
.MuiToolbar-root input {
  /* color: #fff; */
}
.cursor-pointer:hover {
  color: var(--portal-theme-primary) !important;
}
.whole-background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
/*=================================  full calender  =======================*/
.calendar-workout-card {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  /* padding: 10px; */
  /* margin: 2px; */
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  position: "relative";
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
}

.calendar-workout-card-program:hover {
  /* text-decoration: underline; */
}
.calendar-workout-card-workout:hover {
  text-decoration: underline;
}
.calendar-workout-card .calendar-workout-card-title {
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 0;
  overflow: hidden;
  white-space: normal;
  color: white;
  padding: 2px;
  border-radius: 5px 5px 0px 0px;
  /* text-overflow: ellipsis; */
}
.calendar-workout-card .calendar-workout-card-container {
  cursor: pointer;
  padding-left: 3px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  color: var(--text-color);
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px; */
}
.calendar-workout-card .calendar-workout-card-container:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  /* font-size: 11px; */
  background-color: rgba(145, 158, 171, 0.08);
  transform: scale(1.06, 1.04);
}
.calendar-workout-card .calendar-workout-card-exercises {
  padding: 7px;
}
.calendar-workout-card .calendar-workout-card-complete-exercises {
  color: var(--text-primary-color);
  font-weight: 500;
  opacity: 0.6;
}
.workout-complete-icon-container {
  color: green;
  margin-right: 2px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid white;
}
.workout-type-icon-container {
  color: var(--portal-theme-primary);
  margin-right: 2px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid white;
  height: 20px;
  width: 20px;
}
.calendar-workout-card .calendar-workout-card-complete-exercises-icon {
  color: green;
  margin-right: 2px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 50%;
  font-size: 11px;
}

.cross-icon {
  display: flex;
  justify-content: end;
  align-items: center;
}
.cross-icon .sync-icon-remove {
  border: 0.1px solid red;
  border-radius: 50%;
  background-color: red !important;
  color: white !important;
  cursor: pointer;
}
.cross-icon .sync-icon-add {
  border: 0.1px solid #dbdbdb;
  border-radius: 50%;
  background-color: white;
  color: #dbdbdb !important;
  cursor: pointer;
}
.outer-div {
  background-color: #f0f0f0; /* Set outer div background color */
  padding: 20px; /* Add some padding for visibility */
}

.inner-div {
  background-color: #ffffff; /* Set inner div background color */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: 5px; /* Add some padding for visibility */
  border-radius: 4%;
}
.cross-icon .sync-icon-remove {
  border: 0.1px solid red;
  border-radius: 50%;
  background-color: red !important;
  color: white !important;
  cursor: pointer;
}
.cross-icon .sync-icon-add {
  border: 0.1px solid #dbdbdb;
  border-radius: 50%;
  background-color: white;
  color: #dbdbdb !important;
  cursor: pointer;
}
.diary-icon-plus-minus {
  color: var(--portal-theme-primary) !important;
  fill: var(--portal-theme-primary) !important;
  margin-bottom: 0px;
  cursor: pointer;
  height: 16px;
  width: 16px;
}
.exercise-cards-shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 16px;
}
.workout-calendar-Superset-card {
  border-left: 2px solid var(--portal-theme-primary);
  border-radius: 3px;
  padding: 5px 5px;
}
.workout-calendar-exercise-card {
  border-radius: 3px;
  padding: 5px 5px;
}
.workout-card {
  background-color: var(--sidebars-background-color) !important;
  padding: 20px;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 16px 32px -4px rgb(0 3 82 / 24%);
  border-radius: 5px;
}
.set-parameters {
  color: var(--portal-theme-primary);
  cursor: pointer;
}
.emom-round-title {
  font-weight: 500;
  margin-right: 5px;
}
/* CountBox.css */

.emom-round-count-box-disable {
  width: 25px;
  height: 25px;
  /* background-color: var(--portal-theme-primary); */
  background-color: white;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.emom-round-count-box {
  width: 25px;
  height: 25px;
  background-color: var(--portal-theme-primary);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  cursor: pointer;
}
.emom-round-count-disable {
  font-size: 12px;
  color: var(--portal-theme-primary);
  font-weight: bold;
}
.emom-round-count {
  font-size: 12px;
  color: white;
  font-weight: bold;
}
.workout-card-inset {
  background-color: var(--sidebars-background-color) !important;
  padding: 20px;
  margin-left: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 5px;
  position: relative;
}
.workout-set-card {
  background-color: var(--sidebars-background-color) !important;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 5px;
}
.workout-Superset-card {
  background-color: var(--sidebars-background-color) !important;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-left: 3px solid var(--portal-theme-primary);
  border-radius: 5px;
  margin-bottom: 2px;
}
.workout-Superset-subset-card {
  background-color: var(--sidebars-background-color) !important;
  padding: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; */
  border-left: 2px solid rgb(177 162 174);
  border-radius: 5px;
  margin-bottom: 2px;
}
.icon-calendar-workout {
  position: absolute;
  top: -25px;
  right: 2px;
  opacity: 1;
  transition: opacity 0.3s ease;
  animation-name: exampledel;
  animation-duration: 0.5s;
}
.icon-calendar-workout-small-screen {
  position: absolute;
  top: -13px;
  right: 2px;
  opacity: 1;
  transition: opacity 0.3s ease;

  animation-duration: 0.5s;
}
.del-icon-calendar-workout {
  background-color: red !important;
  border-radius: 50%;
  color: white !important;
  width: 23px;
  height: 23px;

  padding: 3px;
  cursor: pointer;
}
.icon-calendar-workout-small-screen {
  position: absolute;
  top: -13px;
  right: 2px;
  opacity: 1;
  transition: opacity 0.3s ease;
  animation-duration: 0.5s;
}
.del-icon-calendar-workout-notwork {
  background-color: rgb(133, 133, 133) !important;
  border-radius: 50%;
  color: white !important;
  width: 23px;
  height: 23px;

  padding: 3px;
  cursor: pointer;
}
.del-icon-calendar-workout-working {
  background-color: red !important;
  border-radius: 50%;
  color: white !important;
  width: 23px;
  height: 23px;

  padding: 3px;
  cursor: pointer;
}
.add-icon-calendar-workout {
  background-color: green !important;
  border-radius: 50%;
  color: white !important;
  width: 23px;
  height: 23px;

  padding: 3px;
  cursor: pointer;
}

@keyframes exampledel {
  100% {
    right: 2px;
    top: -25px;
    opacity: 1;
    color: white;
  }
  0% {
    top: 3px;
    right: 2px;
    opacity: 0;
    color: wheat;
  }
}
.subset-card {
  background-color: var(--sidebars-background-color) !important;
  /* padding: 20px; */
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 16px 32px -4px rgb(0 3 82 / 24%);
  border-radius: 5px;
}
.subset-workout-card {
  background-color: var(--sidebars-background-color) !important;
  padding: 20px;
  /* border: 1px solid black; */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  /* border-radius: 10px; */
}
.diary-icon-add {
  color: var(--portal-theme-primary) !important;
  cursor: pointer;
}
.diary-icon-remove {
  color: red !important;
  fill: red !important;
  margin-bottom: 0px;
  cursor: pointer;
}
.cross-icon {
  display: flex;
  justify-content: end;
  align-items: center;
}
/* .calendar-workout-card-container {
  width: 100px;
  transition: width 4s;
  display: none;
}

.calendar-workout-card-container.show {
  display: block;
} */
@keyframes examplecontainer {
  100% {
    left: 2px;
    top: -20px;
    opacity: 1;
    color: white;
  }
  0% {
    top: 3px;
    left: 2px;
    opacity: 0;
    color: wheat;
  }
}
.calender-events-box {
  margin-top: 10px;
}

.calender-events-text {
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
}
.fc-daygrid-day-frame {
  overflow: hidden;
}

/* .fc-daygrid-day-events {
  padding-top: 1rem;
  color: white;
} */

.calender-events-text {
  color: white;
}

.full-calendar .fc-day-today {
  /* background-color: var(--calendar-selected-box) !important; */
}
.full-calendar .fc-more-popover {
  background-color: var(--sidebars-active-color);
  z-index: 1;
}
.fc-toolbar-chunk {
  display: inline-flex;
}
.fc-day-grid-event > .fc-content {
  white-space: normal;
}

.eventimage {
  width: 50px;
  height: 50px;
  margin-left: 5px;
  border: solid;
  border-color: var(--portal-theme-primary);
}

.fc-daygrid-event {
  border: none !important;
  background: none !important;
}

.fc-today-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: none !important;
  font-weight: bold !important;
}

.fc-prev-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: none !important;
  box-shadow: none !important;
  border: 1px solid var(--portal-theme-primary) !important;
}

.fc-right {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: none !important;
  box-shadow: none !important;
}

.fc-next-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: none !important;
  box-shadow: none !important;
  border: 1px solid var(--portal-theme-primary) !important;
}

.fc-dayGridMonth-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: solid !important;
  border-color: var(--portal-theme-primary) !important;
  box-shadow: none !important;
}

.fc-timeGridWeek-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: solid !important;
  border-color: var(--portal-theme-primary) !important;
  box-shadow: none !important;
}

.fc-timeGridDay-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: solid !important;
  border-color: var(--portal-theme-primary) !important;
  box-shadow: none !important;
}

.fc-col-header-cell-cushion {
  color: var(--portal-theme-primary);
  text-decoration: none;
}

.fc-scrollgrid-section {
  border-style: none;
}

.fc-daygrid-day-number {
  text-decoration: none;
  color: var(--input-text-color);
  font-weight: bold;
}
.fc .fc-daygrid-day-top {
  display: contents;
}

.fc-scrollgrid-sync-table {
  border-top: thick !important;
}

.dropdownbutton {
  border-color: #ccc5c5 !important;
  font-size: 12px !important;
}

.dialog-img {
  border: none;
  z-index: 1;
  top: 60%;
  left: "center";
}

.dialog-image {
  width: 400px;
  height: 400px;
}

.dialog-detail {
  border-color: #42969c;
  border-radius: 5px;
  position: static;
  z-index: 1;
  top: 100%;
  left: 20%;
  width: 400px;
}

.log-detail {
  width: 120px;
}
.social-set {
  border: solid;
  border-color: #ccc5c5;
  border-radius: 50px;
  display: flex;
}
.dl-button {
  border: 1px solid #ccc5c5;
  padding: 5px;
  display: flex;
}
.dl-button2 {
  border: 1px solid var(--background-primary-color);
  padding: 5px;
  display: flex;
}

/* pinterest layout design */

.pinterest-card span {
  color: var(--text-secondary-color);
  font-size: 14px;
  font-weight: 600;
  margin-top: 15px;
  display: block;
  padding: 0px 15px;
}

#columns {
  column-width: 280px;
  column-gap: 15px;
  width: 100%;
  max-width: 1100px;
  margin: 50px auto;
  margin-top: 0;
}

div#columns div {
  background-color: var(--background-secondary-color);
  border-radius: 16px;
  margin-bottom: 15px;
}
div.show-pointer div:hover {
  cursor: pointer;
}
.pinterest-card {
  position: relative;
}
div#columns figure {
  margin: 0 2px 15px;
  transition: opacity 0.4s ease-in-out;
  border-radius: 16px;
  display: inline-block;
  width: 99%;
  padding-bottom: 0px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 0;
}

div#columns figure img {
  width: 100%;
  height: auto;
  margin-bottom: 5px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

div#columns figure p {
  margin: 4px 0;
  padding: 15px 15px 20px;
  text-align: left;
  padding-bottom: 0;
  margin-bottom: 15px;
}

div#columns small {
  font-size: 1rem;
  float: right;
  text-transform: uppercase;
  color: #aaa;
}

div#columns small a {
  color: #666;
  text-decoration: none;
  transition: 0.4s color;
}
/* Custom styles for Next button */
.custom-next-arrow {
  color: #fff;
  font-size: 24px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: -20px; /* Adjust the right distance as needed */
  transform: translateY(-50%);
  color: var(--text-primary-color);
}

/* Custom styles for Previous button */
.custom-prev-arrow {
  color: #fff;
  font-size: 24px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  z-index: 1;
  left: -20px; /* Adjust the left distance as needed */
  transform: translateY(-50%);
  color: var(--text-primary-color);
}
.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* div#columns:hover figure:not(:hover) {
  opacity: 0.4;
} */
.responce-messages .set-image-width {
  width: 23% !important;
  padding-left: 0% !important;
  padding-right: 0% !important;
}
.responce-messages .set-title-width {
  width: 57% !important;
}
.event-title-dot {
  height: 10px;
  width: 10px;
  margin-top: 4px;
  margin-right: 3px;
  border-radius: 50%;
}
.calendar-event-title {
  color: var(--text-color);
  margin-bottom: 5px;
  font-size: 13px;
}
.calendar-event-title:hover {
  color: var(--portal-theme-primary);
}
.calendar-events-text {
  color: white;
}
.toollbar-group-buttons .small-contained-button {
  border-radius: 0;
  color: #fff;
  outline: none !important;
  background-color: transparent !important;
  padding: 0.4em 0.65em;
}

.toollbar-group-buttons .small-contained-button svg {
  color: var(--portal-theme-primary) !important;
  font-size: 16px;
}

.toollbar-group-buttons .small-contained-button:first-child {
  border-radius: 5px 0 0 5px;
}
.toollbar-group-buttons .small-contained-button:last-child {
  border-radius: 0 5px 5px 0;
}
.toollbar-group-buttons .selected-button {
  color: var(--portal-theme-primary);
}
.toollbar-group-buttons .not-selected-button {
  color: var(--input-text-color) !important;
}
.full-calendar-new-flow .fc-header-toolbar {
  display: none;
}
.calendar-card {
  background-color: var(--sidebars-background-color);
  border-radius: 6px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.calendar-week-card {
  background-color: var(--sidebars-background-color);
  border: 7px solid var(--background-primary-color);
  border-radius: 13px;
}
.fc-daygrid-day .start_time_for_popup {
  display: none;
}
/* .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  position: absolute !important;
  margin-top: 30px;
  top: 28%;
  width: 100%;
} */
.fc-daygrid-event-harness-abs {
  /* visibility: visible !important; */
}
.calendar-week-card h4 {
  font-size: 14px;
  margin-bottom: 2px;
}

.calendar-card .card-event-day {
  padding: 5px;
  color: var(--portal-theme-primary);
  text-align: center;
}

.calendar-card .calendar-dot {
  height: 13px;
  width: 13px;
  margin: 0 6px;
  border-radius: 50%;
  border: 1px solid #fff;
}

.calendar-card .card-event-title {
  padding: 5px;
}

.calendar-card .card-event-title:hover {
  color: var(--portal-theme-primary);
}

.calendar-card .card-event-title span {
  color: var(--text-secondary-color);
  font-size: 13px;
  opacity: 0.7;
}

.calendar-card .week-event-title {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.calendar-card .week-event-title span {
  opacity: 0.7;
}
.general-popup-model {
  background-color: var(--sidebars-background-color) !important;
}
.event-description-title {
  color: var(--text-primary-color);
}
.event-description-details {
  color: var(--text-primary-color);
}
.date-color {
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  opacity: 0.5;
}
.icon-event-detail {
  font-size: 18px;
  right: 1%;
  top: 10px;
  justify-content: end;
}

.icon-event-detail .close-detail-icon {
  height: 35px;
  width: 35px;
  text-align: center;
  padding-top: 3px;
  border-radius: 50px;
  background-color: rgb(72 121 207 / 30%);
  color: var(--portal-theme-primary);
  cursor: pointer;
  margin-right: 3px;
}

.icon-event-detail .other-detail-icon {
  height: 35px;
  width: 35px;
  text-align: center;
  padding-top: 3px;
  border-radius: 50px;
  color: var(--portal-theme-primary);
  cursor: pointer;
  margin-right: 3px;
}

.icon-event-detail .other-detail-icon:hover {
  background-color: rgb(72 121 207 / 30%);
}

.icon-event-detail .close-detail-icon svg,
.icon-event-detail .other-detail-icon svg {
  font-size: 16px !important;
}
.perform-action-on {
  min-width: 400px;
  max-width: 100%;
}
.perform-action-on .css-ijridm-MuiTypography-root {
  color: var(--text-color);
}
.post-creator {
  display: flex;
}
.like-profile-image {
  position: relative;
}
.user-info-card .date-color {
  font-size: 12px !important;
}
@media screen and (max-width: 750px) {
  #columns {
    column-gap: 0px;
  }

  #columns figure {
    width: 100%;
  }
  .calendar-event-title {
    display: none;
  }
  .event-title-dot {
    margin-top: 0;
  }
}
@media screen and (max-width: 750px) {
  #columns {
    column-gap: 0px;
  }
  #columns figure {
    width: 100%;
  }
}
/* pinterest end */

.profile-details {
  font-weight: 500;
  font-size: 14px;
  margin-top: 12px;
}
.profile-details-padding {
  padding-left: 35px !important;
  padding-right: 35px !important;
}
.profile-heading {
  font-size: 30px;
  padding-left: 35px;
}
.add-category-button {
  margin-right: 44px;
  margin-bottom: 15px;
}

.quotes-heading {
  line-height: 1.235;
  margin-bottom: 25px;
  color: var(--portal-theme-primary);
}

.container h2,
.event-title {
  color: var(--portal-theme-primary);
}

.dashboard-heading {
  color: var(--portal-theme-primary);
  margin: 0px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 2.125rem;
  line-height: 1.235;
  margin-bottom: 40px;
}
.add-button {
  text-align: right;
  padding-top: 0.25rem;
}
.drawer-section {
  max-width: 400px;
}
.MuiDrawer-paperAnchorRight {
  width: 400px !important;
}
.drawer-padding {
  padding: 0 !important;
}
.drawer-cross-btn {
  margin-top: 12px;
  padding: 6px;
  margin-bottom: 0px !important;
}
.drawer-cross-btn svg {
  font-size: 16px;
}
.custom-video-player iframe {
  height: 100% !important;
}
.react-audio-player {
  width: 100%;
}
button.disabled:hover {
  cursor: not-allowed;
}
.fc-theme-standard .fc-popover-header {
  background: rgba(208, 208, 208, 0.3);
  background: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.3));
  background-color: rgb(0 0 0 / 67%);
}
.fc .fc-more-popover .fc-popover-body {
  min-width: 220px;
  padding: 10px;
  background: var(--background-primary-color);
}
.no-access-string {
  width: 100%;
  text-align: center;
  padding: 20px;
  transform: translate(1%, 30%);
}
/* .no-access-string {
  width: 100%;
  text-align: center;
  padding: 20px;
  top: 50%;
  left: 60%;
  transform: translate(1%, 55%);
} */
.no-access-string p {
  margin-bottom: 0 !important;
}
.download-icon {
  text-align: end;
  margin-bottom: 20px;
}
.download-icon .icon-style {
  color: var(--portal-theme-primary);
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  margin-top: 40px;
  right: 10px;
}
.download-icon span {
  height: 20px !important;
  width: 20px !important;
}
.section-button button {
  /* display: block; */
  background-color: #145b6c;
  color: #fff;
  border: none;
  padding: 8px 20px;
  border-radius: 5px;
}
.dot-btn {
  position: absolute;
  right: 0;
  top: 5px;
}
.set-max-height {
  max-height: 200px !important;
}
.goal-statement-messages .tab {
  margin-top: 10px;
}

.responce-messages .set-image-width {
  width: 23% !important;
  padding-left: 0% !important;
  padding-right: 0% !important;
}

/* .event-title {
  background-color: var(--sidebars-background-color);
} */
.SnackbarItem-message {
  padding-right: 40px !important;
}
.SnackbarItem-action svg,
.SnackbarItem-message svg {
  color: var(--input-text-color) !important;
}
swd-pin-field {
  display: flex;
  justify-content: space-between;
}
.responce-messages .set-title-width {
  width: 57% !important;
}
.forms-drawer .simplebar-wrapper {
  height: 100% !important;
}
.circle-image .set-title-width span {
  color: var(--text-primary-color);
}
.circle-images .MuiAvatar-root {
  width: 40px;
  height: 40px;
}
.circle-image .MuiAvatar-root {
  width: 60px;
  height: 60px;
}
.css-15ez4hi-MuiPaper-root-MuiAccordion-root:last-of-type {
  border-radius: 10px !important;
  background-color: var(--background-secondary-color);
  color: var(--text-primary-color);
}

.password-tooltip {
  border: 2px solid var(--portal-theme-primary) !important;
  color: var(--portal-theme-primary) !important;
}
.carousel-container a {
  text-decoration: none;
}
.simplebar-content .css-1gtfl7l {
  width: 100% !important;
  z-index: 1111111111;
}

.css-17mra34-MuiPaper-root-MuiCard-root {
  background-color: var(--background-primary-color);
  color: var(--input-text-color);
}
.zoom-password svg {
  font-size: 16px;
  margin-left: 5px;
}

.snackbar-cross-icon svg {
  color: #fff;
  font-size: 16px;
}
.recordings-card {
  background-color: var(--background-secondary-color);
  margin-bottom: 20px;
  padding: 20px;
}

audio::-webkit-media-controls-panel {
  width: 100% !important;
  background-color: var(--audio-color) !important;
  border-radius: 50px;
}

.dashboard_description img {
  margin: 0px auto !important;
}
/* .dashboard_description p {
  color: var(--text-primary-color) !important;
} */

.image-slider .image-gallery-icon svg {
  height: 40px;
  width: 40px;
  font-size: 16px;
  color: var(--button-text-color);
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
}

.sidebar-drawer {
  background-color: var(--background-primary-color);
}

.date-picker button {
  background-color: red;
}

.goal_achieved_lower_text {
  display: none;
}
@media screen and (max-width: 1024px) {
  .quotes-heading {
    margin-bottom: 40px;
  }
  .add-category-button {
    margin-right: 20px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 769px) {
  .profile-button-padding {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .add-category-button {
    margin-right: 40px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 576px) {
  .add-button {
    text-align: center;
    padding-top: 0;
    margin-bottom: 30px;
  }
  .MuiDrawer-paperAnchorRight {
    width: 100% !important;
  }
  .lesson-card-image {
    width: 100%;
  }
  .kims-reply-card .set-image-width {
    width: 23% !important;
  }
  .kims-reply-card .set-title-width {
    width: 57% !important;
  }
  .program-btn,
  .program-category-btn {
    text-align: center;
  }

  .goal_achieved_lower_text {
    display: block;
  }
  .goal_achieved_upper_text {
    display: none;
  }
  .dashboard-circle {
    height: 325px;
  }
  .css-qen1a1-MuiDataGrid-root {
    border-left: none !important;
    border-right: none !important;
    padding: 0 10px;
  }
  header img {
    width: 100% !important;
  }
  .SnackbarItem-message {
    max-width: 100%;
  }
  .support-ticket-button-contained,
  .support-ticket-button-outlined {
    margin-top: 20px;
  }
}

.fc-more-link,
.fc-button-active {
  color: var(--portal-theme-primary) !important;
}

@media screen and (max-width: 376px) {
  .profile-button {
    margin-bottom: 20px;
    width: 150px;
    margin-left: -10px;
  }
  .profile-image {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .profile-heading {
    font-size: 30px;
    padding-left: 0px;
  }
  .profile-button-padding {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .profile-details-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .add-category-button {
    margin-right: 19px;
    margin-bottom: 15px;
  }
}

/* Comunity */

.new-community-page .banner-section {
  /* padding-top: 20px; */
}

.new-community-page .slick-arrow {
  display: none !important;
}

.new-community-page .jade-img {
  border: 4px solid #eebe60;
  border-radius: 12px;
  display: inline;
}

.new-community-page .jade-div {
  position: absolute;
  bottom: -10px;
  left: 30px;
}

.new-community-page .img-left {
  margin-left: -25px;
  padding-top: 66px;
  display: inline;
}

/* membership-section  */
.new-community-page .back-light-div {
  background: #1d1c1d;
  border-radius: 10px;
}

.new-community-page .border-right {
  border-right: 3px solid #eebe60;
}

.favourite-box {
  height: 40px;
  width: 40px;
  border: 1px solid #ffff;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  font-size: 8px;
}

.score-row img {
  width: 30px;
}

.favourite-box img {
  display: inline;
}

.favourite-box p {
  margin-bottom: 0;
}

.new-community-page .membership-section button {
  border: 1px solid #fff;
  border-radius: 7px;
  height: 29px;
  width: 85%;
  background: transparent;
  color: #fff;
  text-decoration: none;
  font-size: 10px;
}

.new-community-page .position-img {
  position: absolute;
  right: -13px;
  top: -60px;
}

.new-community-page .position-img img,
.new-community-page .position-img-1 img {
  height: 110px;
  width: 1px;
}

.board-main-image-box,
.board-inner-image-box,
.leader-board-component {
  position: relative;
}

.leader-board-component .leader-board-info-icon {
  position: absolute;
  right: 7px;
  top: 7px;
  width: 13px;
}

.board-main-image-box .board-image-icon {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.board-inner-image-box .board-image-icon {
  position: absolute;
  left: -6px;
  bottom: -3px;
}

.search-input input {
  background-color: #262626 !important;
  margin-bottom: 10px;
  font-size: 13px;
  border-radius: 20px;
  border: none !important;
  height: 35px;
  padding-left: 15px;
}

/* .search-input input:focus {
  border: none;
  background-color: #262626;
} */

.favourite-buttton-box button {
  background-color: #fff !important;
  border: none !important;
  margin-bottom: 10px;
  font-size: 13px;
  color: #000 !important;
}

.favourite-buttton-box svg {
  color: var(--button-text-color);
  font-size: 20px;
  margin-top: -3px;
}

.new-community-page .position-img-1 {
  position: absolute;
  right: 6px;
  top: -60px;
}

.open-create-post-card .profile-cards {
  min-height: auto;
}
.input-with-profile img {
  width: 8%;
  height: auto;
  border-radius: 50%;
  margin-right: 2%;
}
.input-with-profile-feed img {
  border-radius: 50%;
  margin-right: 2%;
}
.input-with-profile .input-field {
  background-color: #f1eee6;
  width: 85%;
  border-radius: 30px;
  border: none;
  padding: 0 10px;
  line-height: 37px;
  cursor: pointer;
}
.input-with-profile-feed .input-field {
  background-color: #04abbb17;
  width: 85%;
  border-radius: 30px;
  border: none;
  padding: 0 10px;
  line-height: 37px;
  cursor: pointer;
}

.search-input ::placeholder {
  color: #fff;
}

.likes-post {
  justify-content: start;
  font-size: 13px;
}

.likes-post img {
  display: inline;
  height: 15px;
  width: 16px;
}

.likes-post svg {
  font-size: 20px;
}

.likes-post span:hover {
  text-decoration: underline;
  cursor: pointer;
}
.upload-video .MuiChip-root,
.comment-icons .MuiChip-root {
  width: 100%;
  cursor: pointer;
}
.comment-icons .MuiChip-root {
  border: none;
}
/* 
.likes-post .MuiAvatar-colorDefault {
  display: none !important;
} */

.upload-video .MuiChip-root svg,
.comment-icons .MuiChip-root svg {
  font-size: 20px;
  margin-left: 3px;
  /* fill: #fff; */
}

.upload-video .MuiChip-root span,
.comment-icons .MuiChip-root span {
  color: #fff !important;
  font-size: 12px !important;
}

.post-detail-page .comment-icons .MuiChip-root {
  width: 130px;
  cursor: pointer;
}

.view-comment span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.new-community-page .img-back-border img {
  display: inline;
  width: 50px;
}
.new-community-page .img-back-border {
  /* background: url(../images/back-batch.png); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5px 15px 4px 11px;
  text-align: center;
}

/* success-wrapper  */
.new-community-page .success-wrapper {
  padding-top: 80px;
}

.new-community-page .success-wrapper h1 {
  font-size: 25px;
  font-weight: 600;
  color: #fff;
}

.new-community-page .success-wrapper h2 {
  font-size: 15px;
  font-weight: 300;
  margin: 13px 0px;
  color: #fff;
}

.new-community-page .success-wrapper span {
  color: #93b36c;
}

.new-community-page .success-wrapper p {
  line-height: 2;
}

.new-community-page .btn-wrapper button {
  border: none;
  padding: 4px 20px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
}

.new-community-page .btn-wrapper .play-btn {
  background: #fff;
  color: #000;
}

.new-community-page .btn-wrapper .more-info-btn {
  background: #555454;
  color: #fff;
}

.new-community-page .slider-lower-buttons button {
  border: 1px solid #fff;
  border-radius: 7px;
  height: 29px;
  background: transparent;
  color: #fff;
  text-decoration: none;
  font-size: 10px;
  font-weight: 100;
  letter-spacing: 3px;
  width: 130px;
  text-align: left;
  padding-left: 10px;
}

.new-community-page .activity-profile img {
  width: 30px;
  margin-top: 5px;
}

.new-community-page .wealth-wallet {
  position: relative;
}
.new-community-page .wealth-wallet .i-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  width: 20px;
  cursor: pointer;
}
.score-right-side h2 {
  margin-bottom: 0.8rem;
  color: #d74336;
}
.score-right-side h5 {
  margin-bottom: 0.8rem;
}

.main-section {
  position: relative;
}

.main-section hr {
  margin: 7px auto;
}
.new-community-page .info-popup-box {
  position: absolute;
  left: -30px;
  top: -100px;
  padding: 5px;
  background-color: #000;
  border-radius: 10px;
  z-index: 2;
  max-width: 350px;
}

.new-community-page .info-popup-box .info-card {
  padding: 20px;
  border: 2px solid #fff;
  border-radius: 10px;
  position: relative;
}

.new-community-page .cross-sigh {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 5px;
}

.new-community-page .cross-sigh span {
  padding: 0px 1px 4px;
  border: 2px solid #fff;
  border-radius: 5px;
}

.new-community-page .cross-sigh span svg {
  /* fill: #fff; */
  color: #fff;
  padding: 2px;
}

.new-community-page .info-popup-box .info-card h2 {
  color: #fff;
  font-size: 20px;
}

.new-community-page .info-popup-box .info-card p {
  /* color: #fff; */
  font-size: 8px;
}

.new-community-page .join-today-btn {
  color: #000;
  background-color: var(--portal-theme-primary);
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 7px;
  cursor: pointer;
  margin-top: 80px;
}
.new-community-page .wealth-wallet h5,
.new-community-page .wealth-wallet span {
  font-size: 10px;
}
.new-community-page .wealth-wallet span {
  color: #28a745;
}
.new-community-page .wealth-wallet h2 {
  font-size: 12px;
}

.new-community-page .community-current-pods p {
  font-size: 8px;
}

.new-community-page .community-current-pods svg {
  font-size: 20px;
}

.new-community-page .live-svg svg {
  color: #93b36c;
}

.new-community-page .starting-svg svg {
  color: #ffc107;
}

.new-community-page .total-points .icon-image img {
  width: 30px;
  margin-top: 7px;
}

.new-community-page .upcoming-events p {
  font-size: 7px;
  color: var(--text-secondary-color);
}

.new-community-page .community-star-icon img {
  width: 40px;
  margin: 0 auto;
}

.new-community-page .comment-icons img {
  display: inline;
  width: 16px;
  margin: 0;
}

.community-slider,
.increase-scale {
  position: relative;
}

.increase-scale svg {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 30px;
  color: #000;
}

.increase-scale .star-icon svg {
  color: var(--button-text-color);
}

.community-slider .increase-scale img {
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.community-slider .increase-scale img:hover {
  transform: scale(1.3);
}

/* .community-slider .increase-scale {
  z-index: 11;
  transform: scale(5);
  top: 20px;
} */

.hide-on-desktop {
  display: none;
}

.create-post-title-box {
  position: relative;
}

.create-post-title-box span {
  position: absolute;
  right: 10px;
  top: -10px;
  height: 35px;
  width: 35px;
  font-size: 20px;
  border-radius: 50%;
  padding-top: 1px;
  background-color: var(--portal-theme-secondary);
}
.create-post-title-box h2 {
  color: #fff;
}

.create-post-title-form .upload-button label {
  width: 100%;
  border: 1px solid white;
  height: auto;
}
.css-1lc5ta7 {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 64px;
  background: transparent;
}
.css-r13mc8 {
  color: var(--portal-theme-primary);
}

.create-post-title-form .upload-button {
  position: relative;
}

.create-post-title-form .upload-button span {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 25px;
  height: 25px;
  background-color: var(--portal-theme-primary);
  color: #fff;
  font-size: 16px;
  border-radius: 50%;
}

.create-post-title-form .preview img {
  width: 100%;
  height: auto;
}

.post-creator {
  display: flex;
}
.post-creator .creator-name h3 {
  font-size: 13px;
}
.post-creator img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  /* margin-right: 15px; */
}

.post-creator select {
  padding: 1px 5px;
  background-color: transparent;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
}

.post-creator select option {
  background-color: var(--sidebars-background-color);
}
.video-image-selection {
  padding: 10px;
  border-radius: 5px;
  display: flex;
}

.video-image-selection .selection-box {
  background-color: var(--portal-theme-primary);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
  padding-top: 9px;
  cursor: pointer;
}

.video-image-selection svg {
  color: var(--input-text-color);
  font-size: 20px;
  /* fill: var(--input-text-color); */
}

.video-image-selection .selected svg {
  color: var(--button-text-color);
  /* fill: var(--button-text-color); */
}

.video-url-field {
  position: relative;
}

.video-url-field .video-url-cross {
  position: absolute;
  right: -4px;
  top: -8px;
  width: 20px;
  height: 20px;
  background-color: var(--portal-theme-primary);
  font-size: 13px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.post-submit-btn {
  background-color: #fff;
  color: #fff;
  border: none !important;
  padding: 5px 30px;
}
.thumbnail {
  position: relative;
  border: 1px solid black;
  background: black;
}

.thumbnail-progress {
  position: absolute;
  width: 100%;
  bottom: 1px;
}
.thumbnail-play-icon {
  position: absolute;
  left: 42%;
  top: 50%;
  transform: translateY(-50%);
  /* transform: translate(-50%); */
}
.pragram-acc {
  position: relative;
}
.pragram-acc-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #000;
  opacity: 0.7;
  border-radius: 0px 0px 20px 20px;
}
.webinar-footer {
  background-color: rgb(23 56 96);
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.event-dot {
  border: 1px solid #fff;
  border-radius: 50%;
  height: 13px;
  margin: 0 6px;
  width: 13px;
}
@media (max-width: 1024px) {
  .new-community-page .membership-section a {
    padding: 7px 5px;
  }

  .new-community-page .membership-section button {
    font-size: 9px;
  }
}

@media (max-width: 912px) {
  .new-community-page .img-back-border-1 {
    /* background: url(../images/back-batch.png); */
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 9px 5px 9px 0px;
  }

  .new-community-page .img-border {
    height: 88px;
  }
  .new-community-page .join-today-btn {
    margin-top: 100px;
  }
  .input-with-profile .input-field {
    line-height: 47px;
  }
  .favourite-box {
    height: 100px;
    width: 100px;
    font-size: 16px;
  }
  .score-right-side h2 {
    margin-bottom: 1.8rem;
    color: #d74336;
  }
  .new-community-page .wealth-wallet h2 {
    font-size: 16px !important;
  }
  .score-right-side h5 {
    font-size: 16px !important;
    margin-bottom: 1.8rem;
  }
  .score-row img {
    width: 60px;
  }
  .hide-on-desktop {
    display: block;
    position: absolute;
    padding: 5px;
    background-color: #000;
    border-radius: 10px;
    z-index: 2;
    max-width: 350px;
    left: auto !important;
    top: 30px !important;
    right: 75px;
  }
  .show-on-desktop {
    display: none;
  }
}

@media (max-width: 820px) {
  .new-community-page .wealth-wallet h5 {
    font-size: 16px;
  }
  .community-card-title h4 {
    font-size: 20px;
  }
  .new-community-page .activity-profile img {
    margin-top: 15px;
  }
  .community-card-title h6 {
    font-size: 18px;
  }
  .score img {
    display: inline;
  }
  .new-community-page .wealth-wallet h2 {
    font-size: 20px;
    margin-top: 20px;
  }
  .new-community-page .community-current-pods p {
    font-size: 16px;
  }
  .community-card-title h5 {
    font-size: 20px;
  }
  .new-community-page .upcoming-events p {
    font-size: 17px;
  }
  .total-points p {
    font-size: 30px;
  }
  .new-community-page .total-points .icon-image img {
    width: 60px;
    margin-top: 7px;
    margin-left: 30px;
  }
  .comment-image img {
    height: 70px;
    width: 70px;
    margin-right: 20px;
  }
  .community-card-title .card-title {
    font-size: 20px;
  }
  .reduce-margin-from-para {
    font-size: 16px;
    font-weight: 600;
  }
  .poster-name .card-title {
    font-size: 16px;
  }
  .poster-name .date-color {
    font-size: 15px !important;
  }
  .new-community-page .wealth-wallet .i-icon {
    position: absolute;
    right: 50px;
    top: 25px;
  }
  .new-community-page .wealth-wallet span {
    font-size: 16px;
  }
  .new-community-page .community-current-pods svg {
    font-size: 30px;
  }

  .community-star-icon {
    text-align: center;
  }
  .new-community-page .join-today-btn {
    font-size: 16px;
    padding: 4px 10px;
  }
}

@media (max-width: 540px) {
  .new-community-page .banner-section,
  .slider {
    padding-top: 35px;
  }

  .new-community-page .success-wrapper {
    padding-top: 33px;
  }
  .new-community-page .slider-lower-buttons {
    text-align: center;
  }
  .favourite-box {
    width: 80px;
    height: 80px;
    font-size: 14px;
  }
  .score-right-side h2 {
    margin-top: 0 !important;
  }

  .new-community-page .comment-icons {
    padding: 0 5px;
  }
  .likes-post {
    font-size: 11px;
  }
}

@media (max-width: 375px) {
  .favourite-box {
    width: 60px;
    height: 60px;
    font-size: 14px;
  }
  .score-right-side h2,
  .score-right-side h5 {
    margin-bottom: 0.8rem;
  }
  .score-row img {
    width: 40px;
  }
  .input-with-profile img {
    width: 15%;
  }
  .community-card-title h4 {
    font-size: 13px;
  }
  .community-card-title h6 {
    font-size: 14px;
  }
  .new-community-page .wealth-wallet h5 {
    font-size: 13px;
  }
  .community-card-title h5 {
    font-size: 16px;
  }
  .new-community-page .community-current-pods p {
    font-size: 12px;
  }
  .new-community-page .upcoming-events p {
    font-size: 12px;
  }
  .favourite-buttton-box {
    margin-top: 15px;
  }
  .community-card-title .card-title {
    font-size: 14px;
  }
  .reduce-margin-from-para {
    font-size: 13px;
  }
  .new-community-page .likes-post img {
    display: inline;
    width: 12px;
    margin: 0;
    height: 12px;
  }
  .comment-icons .MuiChip-label {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.support-ticket-comment-message a {
  color: var(--button-text-color);
  text-decoration: none;
}
.change-password-modal {
  display: flex;
  justify-content: space-between;
}
.image-border {
  border: 2px solid #cacaca;
}

.lesson-text {
  position: absolute;
  bottom: 13px;
  left: 14px;
  right: 0;
}
.lock-icon-color {
  /* color: white !important;
  fill: white !important; */
}
.fc .fc-button-primary {
  text-transform: capitalize;
}
.recommended {
  font-size: 15px !important;
  color: rgb(175, 173, 173) !important;
}
.title {
  text-transform: capitalize;
}
.icon-player {
  width: 50px;
  height: 100px;
}
.tox .tox-notification--warn,
.tox .tox-notification--warning {
  display: none !important;
}
.day-cell-name {
  display: none;
}
.fc-col-header-cell-cushion {
  color: var(--input-text-color);
  text-decoration: none;
}
.tox-notifications-container {
  visibility: hidden !important;
}

@media (max-width: 600px) {
  .fc-daygrid-day {
    display: block; /* Each day box will be displayed in a new line */
    margin-bottom: 8px; /* Add some spacing between day boxes */
  }
  .fc-scrollgrid-section-header {
    display: none;
  }
  .day-cell-name {
    display: inline;
  }
  .fc .fc-daygrid-day {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  .fc .fc-scrollgrid-liquid {
    border: none;
  }
}
@media (max-width: 960px) {
  .fc-daygrid-day {
    display: block; /* Each day box will be displayed in a new line */
    margin-bottom: 8px; /* Add some spacing between day boxes */
  }
  .fc-scrollgrid-section-header {
    display: none;
  }
  .day-cell-name {
    display: inline;
  }
  .fc .fc-daygrid-day {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  .fc .fc-scrollgrid-liquid {
    border: none;
  }
}
.group-card {
  background-color: var(--background-secondary-color);
  /* border: 1px solid #808080; */
  box-shadow: 0 0 3px 0 rgb(0 3 82 / 7%), 0 4px 12px -2px rgb(0 3 82 / 13%);
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 15px;
}
.single-comment-card-dots .menu-background-circle {
  height: 30px;
  width: 30px;
  border-radius: 51px;
  background-color: #145b6c29;
}
.new-memories {
  background-color: var(--sidebars-background-color);
  color: var(--portal-theme-primary);
}

.new-memories h1 {
  font-size: 20px;
  margin: 20px auto;
}
.circle-image .set-title-width span {
  color: var(--text-primary-color);
}
.kims-reply-card .set-title-width {
  width: 57% !important;
}
.show-all-likes .post-creator .creator-name span {
  font-size: 12px !important;
}
.post-creator .creator-name h3 {
  font-size: 13px;
}
.post-creator img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.post-creator select {
  padding: 1px 5px;
  background-color: transparent;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
}

.post-creator select option {
  background-color: var(--sidebars-background-color);
}
.show-all-likes .post-creator .creator-name h3 {
  color: #000;
  text-align: start;
  margin-bottom: 0 !important;
}
.poster-name .card-title {
  font-size: 14px;
}

.poster-name .date-color {
  font-size: 11px !important;
}
.post-description {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  margin-bottom: 10px;
}

.post-description p {
  font-weight: 300;
  display: inline;
  font-size: 14px;
}

.post-description a {
  color: var(--button-text-color);
  text-decoration: none;
  z-index: 1;
}
.main-section hr {
  margin: 7px auto;
}
.post-description span {
  z-index: 111;
  color: var(--button-text-color);
}
.inception .post-description {
  margin-bottom: 4px !important;
}

.inception .post-description span p {
  font-size: 14px !important;
  font-weight: 400;
  color: var(--text-secondary-color);
}
.inception .post-description span {
  font-size: 13px !important;
  font-weight: 400;
  color: var(--button-text-color);
  z-index: 0;
}
.live-event-comment-message {
  margin-top: 5px;
}
.favourite-videos-list,
.feed-image,
.post-description {
  cursor: pointer;
}
.feed-image img {
  width: 100%;
  object-fit: cover;
}

.single-comment-card {
  background-color: #04abbb17;
  padding: 10px;
  border-radius: 3px;
}

.single-comment-card .post-description {
  margin-bottom: 0;
}

/* .single-comment-card-dots .remove-border {
  top: 0 !important;
} */

.single-comment-card .card-title {
  font-size: 14px;
}
.inception .affirmation-dots .remove-border {
  top: 4px;
  right: -2px;
  width: 20px;
  height: 20px;
}

.inception .affirmation-dots .remove-border svg {
  font-size: 14px !important;
}
.video-container {
  max-width: 100%;
  margin: 0 auto;
}
iframe {
  max-width: 100% !important;
}
.live-comments {
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #555;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
/* feeds */
.input-with-profile img {
  border-radius: 50%;
  margin-right: 2%;
}

.input-with-profile .input-field {
  background-color: #f1eee6;
  width: 85%;
  border-radius: 30px;
  border: none;
  padding: 0 10px;
  line-height: 37px;
  cursor: pointer;
  font-size: 14px;
}
@media (max-width: 912px) {
  .new-community-page .img-back-border-1 {
    /* background: url(../images/back-batch.png); */
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 9px 5px 9px 0px;
  }

  .new-community-page .img-border {
    height: 88px;
  }

  .new-community-page .join-today-btn {
    margin-top: 100px;
  }

  .input-with-profile .input-field {
    line-height: 47px;
  }

  .favourite-box {
    height: 100px;
    width: 100px;
    font-size: 16px;
  }

  .score-right-side h2 {
    margin-bottom: 1.8rem;
    color: #d74336;
  }

  .new-community-page .wealth-wallet h2 {
    font-size: 16px !important;
  }

  .score-right-side h5 {
    font-size: 16px !important;
    margin-bottom: 1.8rem;
  }

  .score-row img {
    width: 20px;
  }

  .hide-on-desktop {
    display: block;
    position: absolute;
    padding: 5px;
    background-color: #000;
    border-radius: 10px;
    z-index: 2;
    max-width: 350px;
    left: auto !important;
    top: 30px !important;
    right: 75px;
  }

  .show-on-desktop {
    display: none;
  }

  .comment-icons .MuiChip-label {
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-size: 12px !important;
  }
}

@media (max-width: 820px) {
  .new-community-page .wealth-wallet h5 {
    font-size: 16px;
  }

  .community-card-title h4 {
    font-size: 20px;
  }

  .new-community-page .activity-profile img {
    margin-top: 15px;
  }

  .community-card-title h6 {
    font-size: 18px;
  }

  .score img {
    display: inline;
  }

  .new-community-page .wealth-wallet h2 {
    font-size: 20px;
    margin-top: 20px;
  }

  .new-community-page .community-current-pods p {
    font-size: 16px;
  }

  .community-card-title h5 {
    font-size: 20px;
  }

  .new-community-page .upcoming-events p {
    font-size: 17px;
  }

  .total-points p {
    font-size: 30px;
  }

  .new-community-page .total-points .icon-image img {
    width: 60px;
    margin-top: 7px;
    margin-left: 30px;
  }

  .comment-image img {
    height: 70px;
    width: 70px;
    margin-right: 20px;
  }

  .community-card-title .card-title {
    font-size: 20px;
  }

  .reduce-margin-from-para {
    font-size: 16px;
    font-weight: 600;
  }

  .poster-name .card-title {
    font-size: 16px;
  }

  .poster-name .date-color {
    font-size: 14px !important;
  }

  .new-community-page .wealth-wallet .i-icon {
    position: absolute;
    right: 50px;
    top: 25px;
  }

  .new-community-page .wealth-wallet span {
    font-size: 16px;
  }

  .new-community-page .community-current-pods svg {
    font-size: 30px;
  }

  .community-star-icon {
    text-align: center;
  }

  .new-community-page .join-today-btn {
    font-size: 16px;
    padding: 4px 10px;
  }

  .wider-card .mui-card-button,
  .mui-card-button {
    left: 27%;
  }
}

@media (max-width: 540px) {
  .new-community-page .success-wrapper {
    padding-top: 33px;
  }

  .new-community-page .slider-lower-buttons {
    text-align: center;
  }

  .favourite-box {
    width: 80px;
    height: 80px;
    font-size: 14px;
  }

  .score-right-side h2 {
    margin-top: 0 !important;
  }
  .likes-post {
    justify-content: start;
    font-size: 13px;
  }

  .likes-post .MuiAvatarGroup-avatar {
    width: 12px;
    height: 12px;
    font-size: 11px;
  }

  .likes-post img {
    display: inline;
    height: 15px;
    width: 16px;
  }

  .likes-post svg {
    font-size: 20px;
  }
  .likes-post .MuiAvatar-colorDefault {
    display: none !important;
  }
  .likes-post span:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  .new-community-page .comment-icons {
    padding: 0 5px;
  }

  .likes-post {
    font-size: 11px;
  }

  .post-detail-page .main-section {
    max-height: 100vh;
    overflow: auto;
  }

  .community-main-section {
    overflow: auto;
  }
}
@media (max-width: 375px) {
  .favourite-box {
    width: 60px;
    height: 60px;
    font-size: 14px;
  }

  .btn-wrapper button {
    font-size: 13px;
  }

  .score-right-side h2,
  .score-right-side h5 {
    margin-bottom: 0.8rem;
  }

  .score-row img {
    width: 40px;
  }

  .community-card-title h4 {
    font-size: 13px;
  }

  .community-card-title h6 {
    font-size: 14px;
  }

  .new-community-page .wealth-wallet h5 {
    font-size: 13px;
  }

  .community-card-title h5 {
    font-size: 16px;
  }

  .new-community-page .community-current-pods p {
    font-size: 12px;
  }

  .new-community-page .upcoming-events p {
    font-size: 12px;
  }

  .favourite-buttton-box {
    margin-top: 15px;
  }

  .community-card-title .card-title {
    font-size: 14px;
  }

  .reduce-margin-from-para {
    font-size: 13px;
  }

  .new-community-page .likes-post img {
    display: inline;
    width: 12px;
    margin: 0;
    height: 12px;
  }
}
.feed-detail-popup {
  background-color: #000;
  height: 100vh !important;
  /* overflow-y: scroll; */
}
.feed-detail-popup .profile-cards {
  height: 93vh;
  border-radius: 0;
  box-shadow: none;
  overflow: auto;
}
.new-community-page .profile-cards {
  cursor: auto;
}
.emoji-picker-button-creat-post {
  position: absolute;
  top: 0;
  right: 0;
}
.emoji-picker-popup {
  width: 300px;
}

.emoji-picker-popup em-emoji-picker {
  height: 200px;
  width: 300px;
}
.create-post-images span {
  right: 13px;
}
.new-community-page .comment-icons img {
  display: inline;
  width: 16px;
  margin: 0;
}
.post-detail-page .main-section {
  max-height: 80vh;
  overflow: auto;
}
.poster-logo img {
  width: 45px;
}
.post-detail-image iframe {
  width: 100% !important;
}
.feed-level .MuiChip-root {
  color: var(--portal-theme-primary) !important;
  height: 16px;
  font-size: 10px;
  margin-top: 2px;
}
.dynamite-level-image {
  height: 30px !important;
  width: 34px !important;
  margin-top: -4px;
  padding-left: 6px;
  padding-top: 3px;
}
.clear-both {
  clear: both;
}
.feed-detail-popup .comment-icons .MuiChip-label {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.feed-detail-popup .comment-icons .MuiChip-label img {
  display: inline;
  width: 16px;
}
.comment-icons img {
  width: 16px;
  height: 16px;
}
.comment-icons {
  padding: 0px;
}
.user-info-card .date-color {
  font-size: 12px !important;
}
.like-profile-image {
  position: relative;
}

.like-profile-image .tiny-like-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: -10px;
  bottom: -5px;
}

.like-profile-image .tiny-like-icon img {
  width: 13px;
  height: auto;
}
.tiny-like-icon-notification img {
  width: 16px !important;
}

.likes-model-tabs {
  padding: 10px;
}

.likes-model-tabs img {
  margin-right: 8px;
  width: 16px;
  height: 14px;
  margin-top: 2px;
}

.show-all-likes-box {
  overflow: hidden;
}
.cross-show-all-likes-model {
  position: absolute;
  right: 15px;
  top: 10px;
  height: 33px;
  width: 33px;
  font-size: 20px;
  border-radius: 50%;
  padding-top: 1px;
  background-color: var(--portal-theme-secondary);
  z-index: 1;
  text-align: center;
  cursor: pointer;
}
.post-detail-box .cross-show-all-likes-model {
  position: absolute;
  left: 20px;
  top: 20px;
  text-align: center;
  cursor: pointer;
  padding: 1px;
  background-color: #fff;
}
.post-detail-box {
  position: relative;
}
.show-all-likes-tabs img {
  width: 16px;
}
.feed-activity-box {
  max-height: 350px;
  overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.feed-activity-box::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.feed-activity-box {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.load-more-text {
  cursor: pointer;
}
.detail-page-box .profile-main-comments {
  height: 210px;
  overflow: auto;
}

.popup-detail-box .profile-main-comments {
  height: 315px;
  overflow: auto;
}
.add-event-comment .comment-submit-button {
  padding: 4px 6px;
}
.comment-submit-button {
  text-align: center;
  /* background-image: url(../images/btn-img.png); */
  background-color: var(--button-background-color);
  border: 1px solid var(--button-outlined-color);
  color: var(--button-text-color);
  padding: 4px 15px;
  border-radius: 5px;
  margin-bottom: 12px;
}
.css-1ubhkh0-MuiAvatar-root-MuiAvatarGroup-avatar,
.css-1etjxen {
  width: 20px;
  height: 20px;
  font-size: 10px;
  color: #000;
}
.like-comment span {
  color: var(--text-secondary-color);
  margin-right: 10px;
  margin-top: 5px;
}

.like-comment span:hover {
  text-decoration: underline;
}
.like-reply-box span {
  color: #000;
}
.liked-comment {
  color: var(--portal-theme-primary) !important;
}
.comment-like-heart .comment-like-heart-count {
  min-width: 20px;
  text-align: center;
  /* border: 1px solid #333232; */
  border-radius: 50%;
  padding: 0px 8px;
  margin-left: -5px;
  width: 20px;
  height: 18px;
}

.comment-like-heart .comment-like-heart-image {
  min-width: 20px;
  text-align: center;
  /* border: 1px solid #333232; */
  border-radius: 50%;
  padding: 0px 3px;
  width: 29px;
  height: 18px;
}
.live-event-comment-message .comment-like-heart-image,
.live-event-comment-message .comment-like-heart-count {
  max-height: 20px;
}
.view-comment span:hover {
  cursor: pointer;
  text-decoration: underline;
}
.event-comment-like-box .comment-like-heart {
  position: absolute;
  bottom: -10px;
  right: 0;
}
.date-portals {
  color: #858585;
  padding-left: 12px;
  font-size: 15px;
}
.date-portals-title {
  color: white;
  margin-right: 5px;
}

.popup-detail-box {
  height: 100vh;
  overflow: hidden;
}
.detail-page-box {
  height: 90vh;
  overflow: auto;
}
.css-fwfldc-MuiInputBase-root-MuiOutlinedInput-root,
.css-qgdod3 {
  color: white;
}
.feed-video {
  margin: 110px !important;
}
.upload-video .MuiChip-root,
.comment-icons .MuiChip-root {
  width: 100%;
  cursor: pointer;
}
.comment-icons .MuiChip-root {
  border: none;
}
.upload-video .MuiChip-root svg,
.comment-icons .MuiChip-root svg {
  font-size: 20px;
  margin-left: 3px;
  fill: #000;
}
.comment-icons .MuiChip-root:hover {
  background-color: var(--portal-theme-secondary);
}
.post-detail-page .comment-icons .MuiChip-root {
  width: 130px;
  cursor: pointer;
}
.post-detail-page .comment-icons .MuiChip-label {
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-size: 10px !important;
}
.upload-video .MuiChip-root span,
.comment-icons .MuiChip-root span {
  color: #000 !important;
  font-size: 12px !important;
}

.create-post-title-form .upload-button label {
  width: 100%;
  height: auto;
}

.create-post-title-form .upload-button {
  position: relative;
}

.create-post-title-form .upload-button span {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 25px;
  height: 25px;
  color: #fff;
  background-color: var(--portal-theme-primary);
  font-size: 16px;
  border-radius: 50%;
}
.modal-theme {
  background-color: var(--sidebars-background-color);
}
.create-post-title-box {
  position: relative;
}

.create-post-title-box span {
  position: absolute;
  right: -10px;
  top: -10px;
  height: 30px;
  width: 30px;
  font-size: 16px;
  border-radius: 50%;
  padding-top: 1px;
  background-color: var(--portal-theme-secondary);
}

.create-post-title-box h2 {
  color: #000;
}
.video-url-field {
  position: relative;
}

.video-url-field .video-url-cross {
  position: absolute;
  right: -4px;
  top: -8px;
  width: 20px;
  height: 20px;
  background-color: var(--portal-theme-primary);
  font-size: 13px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.video-image-selection {
  padding: 10px;
  border-radius: 5px;
  display: flex;
}

.video-image-selection .selection-box {
  background-color: var(--portal-theme-primary);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
  padding-top: 9px;
  cursor: pointer;
}

.video-image-selection svg {
  color: var(--input-text-color);
  font-size: 20px;
  fill: var(--input-text-color);
}

.video-image-selection .selected svg {
  color: var(--button-text-color);
  fill: var(--button-text-color);
}
.post-submit-btn {
  padding: 5px 30px;
}
.event-chat-emoji-picker-button img {
  width: 23px;
  height: 23px;
  cursor: pointer;
}
.event-chat-emoji-picker-button {
  padding: 6px;
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
  width: 35px;
}
.open-create-post-card .profile-cards {
  min-height: auto;
}
.new-community-page .wealth-wallet {
  position: relative;
}
.new-community-page .wealth-wallet .i-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  width: 20px;
  cursor: pointer;
}
.new-community-page .wealth-wallet h5,
.new-community-page .wealth-wallet span {
  font-size: 10px;
}
.new-community-page .wealth-wallet span {
  color: #28a745;
}
.new-community-page .wealth-wallet h2 {
  font-size: 12px;
}
.post-by .MuiChip-root {
  color: #fff !important;
  height: 16px;
  font-size: 10px;
  margin-top: 0px;
  margin-left: 5px;
  border-radius: 4px;
}
/* .post-by {
  justify-content: space-between;
} */
.new-community-page .slick-arrow {
  display: none !important;
}
.new-community-page .jade-img {
  border: 4px solid #eebe60;
  border-radius: 12px;
  display: inline;
}
.new-community-page .jade-div {
  position: absolute;
  bottom: -10px;
  left: 30px;
}
.new-community-page .img-left {
  margin-left: -25px;
  padding-top: 66px;
  display: inline;
}
.new-community-page .back-light-div {
  background: #1d1c1d;
  border-radius: 10px;
}
.new-community-page .border-right {
  border-right: 3px solid #eebe60;
}
.new-community-page .membership-section button {
  border: 1px solid #fff;
  border-radius: 7px;
  height: 29px;
  width: 85%;
  background: transparent;
  color: #fff;
  text-decoration: none;
  font-size: 10px;
}
.new-community-page .position-img {
  position: absolute;
  right: -13px;
  top: -60px;
}

.new-community-page .position-img img,
.new-community-page .position-img-1 img {
  height: 110px;
  width: 1px;
}
.new-community-page .position-img-1 {
  position: absolute;
  right: 6px;
  top: -60px;
}
.new-community-page .img-back-border img {
  display: inline;
  width: 50px;
}
.new-community-page .img-back-border {
  /* background: url(../images/back-batch.png); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5px 15px 4px 11px;
  text-align: center;
}
.profile-comments .affirmation-dots .remove-border {
  top: 5px;
  width: 28px;
  height: 28px;
}

.profile-comments .affirmation-dots .remove-border svg {
  font-size: 16px !important;
}

.profile-comments.inception {
  background-color: var(--sidebars-background-color);
  color: #fff;
}
.image-slider {
  margin: 21px 50px 10px 50px !important;
}
.image-slider .image-gallery-icon svg {
  height: 40px;
  width: 40px;
  font-size: 16px;
  color: var(--button-text-color);
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
}
.image-slider .image-thumbnails {
  display: flex;
  justify-content: center;
}

.image-slider .main-image img {
  max-height: 400px;
}
.image-slider .image-thumbnails img {
  width: 60px;
  height: 50px;
  cursor: pointer;
}
.image-slider .image-thumbnails .is_selected {
  border: 1px solid var(--portal-theme-primary);
  padding: 3px;
}
.groups-menu-option {
  cursor: pointer;
  font-size: 35px;
  color: rgb(193, 157, 96);
  background-color: rgba(255, 165, 0, 0.2);
  border-radius: 50px;
  padding: 5px;
}
.groups-text {
  font-size: 1.2rem;
  font-weight: 600;
  /* margin-bottom: 0.5rem !important; */
  margin-top: 3px;
  color: var(--portal-theme-primary);
  margin-left: 5px;
}
.groups-text-feeds {
  font-size: 1rem;
  font-weight: 400;
  color: var(--portal-theme-primary);
  text-transform: capitalize;
  margin: auto;
}
.css-105tfim {
  color: var(--portal-theme-primary);
}

.profile-comments .date-color {
  color: #8a8a8a !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  opacity: 0.9;
}
.exit-icon {
  width: 23px;
  height: 23px;
  margin-right: 5px;
}
.exercise-label-box {
  border: 1px solid #ccc;
  width: 100%;
  padding: 4px 6px;
  border-radius: 8px;
  position: relative;
}
.exercise-label {
  min-height: 40px;
  cursor: pointer;
}
.outer-label {
  position: absolute;
  top: -12px;
  left: 12px;
  background-color: #fff;
  font-size: 12px;
  padding: 0 2px;
  color: rgb(141, 141, 141);
}
.centered {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 12px;
}
.page-detail-box .profile-main-comments {
  height: 230px;
  overflow: auto;
}
.programmes-menu-option {
  background-color: white;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 26px;
  /* border: 1px solid gray; */
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 16px 32px -4px rgb(0 3 82 / 24%);

  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
  position: absolute;
  right: 7px;
  top: 7px;
}
.css-n2f66v {
  display: none !important;
}
.notifications-circle {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
  font-size: 12px;
  margin-right: 5px;
}

.notifications-circle-seen {
  color: rgb(51 56 61 / 8%);
  fill: rgb(51 56 61 / 8%);
}
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 27px 0px;
}
.fc .fc-view-harness {
  height: 100vh !important;
}
.css-8ia9hj-MuiButtonBase-root-MuiListItemButton-root:hover {
  /* background-color: rgba(145, 158, 171, 0.08); */
  background-color: var(--portal-theme-secondary) !important;
}
.css-1d9h7hp-MuiButtonBase-root-MuiListItemButton-root:hover {
  /* background-color: rgba(145, 158, 171, 0.08); */
  background-color: var(--portal-theme-secondary) !important;
}
.css-1jvq035-MuiButtonBase-root-MuiListItemButton-root:hover {
  background-color: var(--portal-theme-secondary) !important;
}
.css-1sqs64g-MuiButtonBase-root-MuiListItemButton-root:hover {
  background-color: var(--portal-theme-secondary) !important;
}
.css-r2mhbb-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: var(--portal-theme-secondary) !important;
}
.css-10djxzt-MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: var(--portal-theme-secondary) !important;
}
.css-xflbam:hover {
  background-color: var(--portal-theme-secondary) !important;
}
.css-zuarfm:hover {
  background-color: var(--portal-theme-secondary) !important;
}
.css-168gbuz:hover {
  background-color: var(--portal-theme-secondary) !important;
}
.css-e86akp.MuiTableRow-hover:hover {
  background-color: var(--portal-theme-secondary) !important;
}
.MuiAutocomplete-option:hover {
  background-color: var(--portal-theme-secondary) !important;
}
.MuiListItemButton-root:hover {
  background-color: var(--portal-theme-secondary) !important;
}
.css-ivvxqp:hover {
  background-color: var(--portal-theme-secondary) !important;
}
.image-gallery-thumbnails-wrapper {
  /* display: none; */
}
.feed-video-page {
  margin: 126px 20px !important;
}
.detail-page .image-slider {
  margin: 29px 50px 10px 50px !important;
}
.css-145s6jh.MuiTableRow-hover:hover {
  background-color: var(--portal-theme-secondary);
}
.css-15scq06.Mui-selected {
  background-color: var(--portal-theme-secondary);
}
@media (max-width: 768px) {
  .feed-detail-popup {
    overflow-y: scroll;
  }
  .detail-page .image-slider {
    margin: 10px 50px 10px 50px !important;
  }
}

@media (max-width: 540px) {
  .disPlay-not-sm {
    display: none;
  }
  .disPlay-on-sm {
    display: block;
  }
  .remove-margen {
    margin: 0px !important;
  }
  .font-size-on-sm {
    font-size: 16px;
  }
  .group-buttons-font-size-on-sm {
    font-size: 12px;
  }
}
.detail-page-image {
  display: flex;
  justify-content: center;
  margin: auto;
}
.video-url-field .form-control {
  border: 1px solid #ced4da;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12000;
}
.cross-show-send-coins {
  height: 33px;
  width: 33px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 50%;
  padding-top: 1px;
  background-color: var(--portal-theme-secondary);
  cursor: pointer;
  border: none;
}
.sendcoins-heading {
  color: var(--portal-theme-primary);
  margin-top: 14px;
}
.action-row {
  display: flex;
  justify-content: space-between;
}
.send-points {
  color: var(--portal-theme-primary);
  margin: 5px;
  font-weight: 600;
}
.small-contained-outlined-button {
  background-color: transparent !important;
  border: 1px solid var(--button-outlined-color);
  border-radius: 5px;
  color: var(--portal-theme-primary);
  padding: 6px 16px;
}
/* .label-text {
  font-weight: 500;
} */
@media (max-width: 960px) {
  .calendar-workout-card {
    font-size: 12px !important;
  }
  .calendar-workout-card .calendar-workout-card-title {
    font-size: 13px !important;
  }
}
.small-textfiel-label-box {
  border: 1px solid #ccc;
  width: 100%;
  padding: 6px 4px;
  border-radius: 8px;
  position: relative;
  height: 36px;
}
.small-textfiel-main-text {
  font-size: 13px;
  color: black;
}
.small-textfiel-label {
  min-height: 40px;
  cursor: pointer;
  font-size: 14px;
}
.small-textfiel-outer-label {
  position: absolute;
  top: -11px;
  left: 12px;
  background-color: #fff;
  font-size: 10px;
  padding: 0 2px;
  color: rgb(141, 141, 141);
  font-weight: 400;
}
@media (max-width: 700px) {
  .set-Type-lable,
  .set-Type-value,
  .textfiels-input-label,
  .textfiels-input-value,
  .workout-set-card,
  .workout-Superset-card {
    font-size: 1rem !important;
  }
  .workout-card-inset {
    margin-left: 0px !important;
  }
}
.overlay-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Additional styling for the overlay text */
}
.max-images {
  margin-bottom: 0px;
  font-size: 12px;
  opacity: 0.9;
  color: #8a8a8a;
  margin-left: 4px;
}
.preview-comment-image span {
  position: absolute;
  right: 3px;
  top: -7px;
  font-size: 13px;
  font-weight: bold;
  height: 20px;
  background-color: red;
  width: 20px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}

.preview-comment-image span {
  right: -10px;
}
.preview-comment-image {
  position: relative;
}

.preview-comment-image img {
  height: 50px;
  width: 50px;
  display: inline;
  object-fit: cover;
}
.post-description img {
  /* height: 150px; */
  width: 130px;
  /* display: inline; */
  object-fit: cover;
}
.dot-filter-count {
  width: 15px;
  height: 15px;
  background-color: whitesmoke;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 10px;
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.custom_description {
  padding: 10px 0px 10px 20px;
  position: relative;
}

.something_else_description {
  padding: 10px 0px 10px 0px;
  position: relative;
}

.something_else_description .custom_description_title {
  left: 4px;
}

.custom_description_title {
  position: absolute;
  left: 23px;
  background: white;
  top: 2px;
  font-size: 11px;
  padding: 0px 8px;
}

.custom_description_text {
  font-size: 14px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

@media (max-width: 576px) {
  .custom_description {
    padding: 10px 0px 10px 0px !important ;
  }
  .custom_description_title {
    left: 4px !important;
  }
}

.custom-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fixed_top {
  position: fixed !important;
  margin-top: -3px;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
}

@media (max-width: 768px) {
  .fixed_top {
    margin-top: 0px !important;
  }
}

.p_top_10 {
  padding-top: 90px !important;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.bounce {
  animation: bounce 0.8s ease 0s infinite;
}

.custom_description_text td {
  white-space: normal !important;
}


.something_else_description {
  overflow-wrap: anywhere !important;
}

.fw-14 {
  font-size: 14px !important;
}

.duplicate-icon-box {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.duplicate-icon,
.duplicate-icon-box svg {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
  cursor: pointer;
}

.food_table>:not(:first-child) { 
  border-top: 0px !important;
}

.food_table td {
  padding-top: 15px;
  padding-bottom: 15px;
}

.food_inputs {
  width: 54px;
  padding: 4px;
  text-align: center;
  border-radius: 7px;
  outline: none;
  border: 1px solid #ccc;
  background: #fff;
}

.calories_text {
  background-color: #ff7f00;  
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.food_meal_card {
  border: 1px solid #00000029;
  padding-top: 20px;
  border-radius: 10px; 
}

.food_meal_card  p {
  margin-bottom: 5px;
}


.primary_color {
  color: var(--portal-theme-primary) !important;
}

.meals_card {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  width: 100%;
  overflow: auto;
}

.fix_icon_remove_food svg {
  position: absolute;
  left: 98%;
  top: -10px;
  background-color: white;

}



